import { Injectable } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";

import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { Router } from "@angular/router";

import { auth } from "firebase/app";

import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  user: firebase.User;

  constructor(
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.user = user;
        localStorage.setItem("howdey_user_details", JSON.stringify(this.user));
        localStorage.setItem("howdey_user_id", JSON.stringify(this.user.uid));

        localStorage.setItem("user_country", "India");
      } else {
        localStorage.setItem("howdey_user_details", null);
        localStorage.setItem("user_country", "India");
      }
    });
  }

  doRegister(value) {
    return this.afAuth.createUserWithEmailAndPassword(
      value.email,
      value.password
    );
  }

  doSignin(value) {
    return this.afAuth.signInWithEmailAndPassword(value.email, value.password);
  }

  async googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    const credential = await this.afAuth.signInWithPopup(provider);
    return credential;
  }

  async fbSignin() {
    const provider = new firebase.auth.FacebookAuthProvider();
    const credentials = await firebase.auth().signInWithPopup(provider);
    return credentials;
  }

  makeUserDoc(value) {
    let add_data = {};

    add_data["email"] = value.email;
    add_data["id"] = value.uid;
    add_data["mobile"] = "";
    add_data["date"] = firebase.firestore.FieldValue.serverTimestamp();
    add_data["name"] = value.displayName;
    add_data["photo_url"] = value.photoURL;
    add_data["total_assessment_attempted"] = 0;

    add_data["total_assessment_created"] = 0;
    add_data["total_class_attended"] = 0;

    add_data["total_class_created"] = 0;

    return this.afs.collection("users").add(add_data);
  }

  async signOut() {
    await this.afAuth.signOut();
    localStorage.removeItem("howdey_user_details");
    localStorage.removeItem("howdey_user_email");
    this.router.navigate(["/"]);
  }
}
