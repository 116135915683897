import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicLinkService {

  private postUrl: string = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyD8zBTOfYPxjCf8mh7jUYHs872o4MVBzEw';
  private prodPostUrl: string = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBaOngNd4dpFaXloeDtN77hrkpYJkleLPI';

  private url: string = 'dev.hurreytech.com';
  private prodUrl: string = 'video.hurreytech.com';

  private domainPrefix: string = 'https://hurreytech.page.link';
  private prodDomainPrefix: string = 'https://hurrey.page.link';

  constructor(private http: HttpClient) { }

  getDynamicUrl = (url: string) => {
    return this.http.post(this.postUrl, {
      "dynamicLinkInfo": {
        "domainUriPrefix": this.domainPrefix,
        "link": url,
        "androidInfo": {
          "androidPackageName": "com.hurreytech.teacher"
        }
      }
    });
  }

  checkAndSetProd() {
    if (environment.production) {
      this.postUrl = this.prodPostUrl;
      this.domainPrefix = this.prodDomainPrefix;
      this.url = this.prodUrl;
    }
  }

  getUrl() {
    if (environment.production) {
      this.postUrl = this.prodPostUrl;
      this.domainPrefix = this.prodDomainPrefix;
      this.url = this.prodUrl;
    }
    return this.url;
  }

}
