import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicLinkService } from './pages/dynamic-link.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

  public sub1: Subscription;

  title = 'Hiric';

  constructor(
    private dLink: DynamicLinkService,
    private router: Router
    ) {}

  ngOnInit() {
    this.dLink.checkAndSetProd();
    // Scroll top on router change
    this.sub1 = this.router.events.subscribe(evt => {
        if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    })
  }

  ngOnDestroy() {
    if (this.sub1) this.sub1.unsubscribe();
  }

}
