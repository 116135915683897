// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAEgDDVu5Y8kowjzhoSai9KQ6KfWp-Lzsg",
    authDomain: "dev.hurreytech.com",
    databaseURL: "https://dev-smartshala.firebaseio.com",
    projectId: "dev-smartshala",
    storageBucket: "dev-smartshala.appspot.com",
    messagingSenderId: "259298741982",
    appId: "1:259298741982:web:6c6685ca04d726d16bfc4f",
  }
};

// dev-smartshala.firebaseapp.com

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
