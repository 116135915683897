import { Component, OnInit  } from '@angular/core';
import { ResponsiveNavService } from 'src/app/pages/home/responsive-nav.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public nav: string;

  switch = 1;
  cur_year = 0;

  constructor(private resNav: ResponsiveNavService) {
   }

  ngOnInit() {
    this.cur_year = new Date().getFullYear();

    this.resNav.getUpdatedNavName()
      .subscribe(data => {
        this.nav = data;
      })
  }

  onChangeSwitch() {
    this.switch == 1 ?  this.switch = 0 : this.switch = 1;
  }
  onChangeColor(color:string)
  {
    document.getElementById("theme_id").className = "";
    document.getElementById('theme_id').classList.add('theme-'+color);
  }

}
