import { AuthService } from "./../../pages/auth.service";
import { Component, Injectable, AfterViewInit } from "@angular/core";
import { ScrollSpyService } from "ngx-scrollspy";
import { Router } from "@angular/router";
import { ResponsiveNavService } from "src/app/pages/home/responsive-nav.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements AfterViewInit {
  sections = {};
  noLinks = false;

  // Brand link
  public brandLink: string = "";

  // Nav mode variable
  public navPage: string = "";

  constructor(
    private scrollSpyService: ScrollSpyService,
    private router: Router,
    private navService: ResponsiveNavService,
    private service: AuthService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    // console.log(this.router.url);
    // if (this.router.url == "/") {
    //   this.noLinks = false;
    //   // document
    //   //   .querySelector('a[data="id_home"]')
    //   //   .setAttribute("style", "color:#1bbc9d!important");
    // } else if (this.router.url == "/join-host") {
    //   this.noLinks = true;
    // }

    // {
    // }

    // subscribing to nav service
    this.navService.getUpdatedNavName().subscribe((name) => {
      this.navPage = name;
      if (name == "main") {
        return (this.brandLink = "/");
      }
      return (this.brandLink = "/join-host");
    });

    this.navService.getNavbarStatus().subscribe(data => {
      switch (data) {
        case 'close': {
          document.getElementById("navbarCollapse").classList.remove('show');
          document.getElementById("menu_button").classList.remove('collapsed');
          break;
        };
        case 'open': {
          document.getElementById("navbarCollapse").classList.add('show');
          document.getElementById("menu_button").classList.add('collapsed');
          break;
        }
      }
    })
  }

  changePage() {
    this.router.navigateByUrl(this.brandLink);
  }

  toggleMenu() {
    console.log('Navbar toggled!');
    if (this.navPage == 'host') {
      this.navService.toggleSidebar();
      return;
    }
    document.getElementById("navbarCollapse").classList.toggle("show");
    document.getElementById("menu_button").classList.toggle("collapsed");
  }

  ngAfterViewInit() {
    // let sections: NodeListOf<Element> = document.querySelectorAll(".section");
    // let self = this;

    // Array.prototype.forEach.call(sections, function (e) {
    //   self.sections[e.id] = parseFloat(e.offsetTop);
    // });

    // console.log(JSON.stringify(self.sections));

    // this.scrollSpyService.getObservable("window").subscribe((e: any) => {
    //   let scrollPosition = document.documentElement.scrollTop;

    //   for (let menu in this.sections) {
    //     if (this.sections[menu] <= scrollPosition) {
    //       if (menu == "" || menu == "testi") {
    //       } else {
    //         if (this.router.url == "/index1" || this.router.url == "/") {
    //           this.clearMenu("#393f4f");
    //           document
    //             .querySelector('a[data="id_' + menu + '"]')
    //             .setAttribute("style", "color:#1bbc9d!important");
    //         } else if (
    //           this.router.url == "/index2" ||
    //           this.router.url == "/index3" ||
    //           this.router.url == "/index5" ||
    //           this.router.url == "/index6" ||
    //           this.router.url == "/index7" ||
    //           this.router.url == "/index8" ||
    //           this.router.url == "/index9"
    //         ) {
    //           this.clearMenu("#ffffff99");
    //           document
    //             .querySelector('a[data="id_' + menu + '"]')
    //             .setAttribute("style", "color:#ffffff!important");
    //         } else if (this.router.url == "/index4") {
    //           this.clearMenu("#393f4f");
    //           document
    //             .querySelector('a[data="id_' + menu + '"]')
    //             .setAttribute("style", "color:#f85f89 !important");
    //         } else {
    //           this.clearMenu("#000000");
    //         }
    //         //   document.getElementById('navbarCollapse').classList.remove('show');
    //       }
    //     }
    //   }
    // });
  }

  clearMenu(color) {
    // document
    //   .querySelector('a[data="id_home"]')
    //   .setAttribute("style", "color:" + color + "!important");
    // document
    //   .querySelector('a[data="id_services"]')
    //   .setAttribute("style", "color:" + color + "!important");
    // document
    //   .querySelector('a[data="id_features"]')
    //   .setAttribute("style", "color:" + color + "!important");
    // document
    //   .querySelector('a[data="id_pricing"]')
    //   .setAttribute("style", "color:" + color + "!important");
    // document
    //   .querySelector('a[data="id_about"]')
    //   .setAttribute("style", "color:" + color + "!important");
    // document
    //   .querySelector('a[data="id_blog"]')
    //   .setAttribute("style", "color:" + color + "!important");
    // document
    //   .querySelector('a[data="id_contact"]')
    //   .setAttribute("style", "color:" + color + "!important");
  }

  // Moved to sidebar
  // logoutModal(logoutModalData) {
  //   this.modal.open(logoutModalData);
  // }

  // logout() {
  //   this.service.signOut();
  // }
}
