import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveNavService {

  // Nav to display
  private navName: string = '';
  private updateNavName = new Subject<string>();
  private navbarStatus = new Subject<string>();

  // sidebar toggle
  private sidebar: boolean = false;
  private updateSidebar = new Subject<boolean>();

  constructor() { }

  // get the name of the current nav
  getNavName = (): string => this.navName;

  // returns the updateNav subject variable
  getUpdatedNavName = () => this.updateNavName.asObservable();

  // put a new nav and update
  putNavName = (navName: string): void => {
    this.navName = navName;
    console.log('Navbar changed to', navName);
    this.updateNavName.next(this.navName);
  }

  // using this to open and close navbar from anywhere else
  changeNavbarStatus(status: string) {
    this.navbarStatus.next(status);
  }

  getNavbarStatus() {
    return this.navbarStatus;
  }

  // toggle sidebar
  toggleSidebar() {
    this.sidebar = !this.sidebar;
    this.updateSidebar.next(this.sidebar);
  }

  setSidebarStatus(condition: boolean) {
    this.sidebar = condition;
    this.updateSidebar.next(this.sidebar);
  }

  getUpdatedSidebar() {
    return this.updateSidebar.asObservable();
  }

}
